const redirects = {
  matching: () => `${getRedirectDomain()}`,
  onboarding: () => `${getRedirectDomain()}/onboarding`
};

export const redirectToPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const matching = queryParams.get("matching");
  const payment_mode = queryParams.get("mode");
  const redirectUrl = (matching && !payment_mode) ? redirects.matching() : redirects.onboarding();

  window.location.href = redirectUrl + window.location.search;
};


const getRedirectDomain = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const funnel_mode = urlParams.get("funnel_mode");

  if (funnel_mode === "test") return process.env.REACT_APP_WEBAPP_DEV_DOMAIN;
  return process.env.REACT_APP_WEBAPP_DOMAIN;
};
